import {
	EmbassyOrConsulateType,
	ImportantContactType,
	LocationsType,
	PoliceAndLawEnforcementType,
} from '../../../api/types/apiGetReport';
import cn from '../../../lib/classNames';

import Accordion from './Accordion';

import styles from './Emergency.module.scss';

type EmergencyType = {
	className?: string;
	data: LocationsType | string;
};

export default function Emergency({ className, data }: EmergencyType) {
	if (typeof data === 'string' || !data?.main_emergency)
		return <section className={cn(styles.emergency, className)}>N/A</section>;

	const renderContactInfo = (
		info: PoliceAndLawEnforcementType | ImportantContactType | EmbassyOrConsulateType,
	) => (
		<div key={info.name} className={styles.contactSection}>
			<h5 className={styles.subtitle}>{info.name}</h5>
			<p className={styles.contactRow}>
				<span className={styles.contactType}>Phone Number: </span>
				<span>{info.phone_number}</span>
			</p>

			{'address' in info && info.address && (
				<p className={styles.contactRow}>
					<span className={styles.contactType}>Address: </span>
					<span>{info.address}</span>
				</p>
			)}

			{'website' in info && info.website && (
				<p className={styles.contactRow}>
					<span className={styles.contactType}>Website: </span>
					<a href={info.website} target="_blank" rel="noopener noreferrer">
						{info.website}
					</a>
				</p>
			)}

			{'description' in info && info.description && (
				<p className={styles.contactRow}>
					<span className={styles.contactType}>Description: </span>
					<span>{info.description}</span>
				</p>
			)}
		</div>
	);

	if (!data) return <section className={cn(styles.emergency, className)}>N/A</section>;

	return (
		<section className={cn(styles.emergency, className)}>
			{/* TODO: need to clarify to use the Accordion here */}
			{/* <Accordion
				description={
					<div className={styles.emergency}>
						<div className={styles.contactSection}>
							<p className={styles.contactRow}>
								<span className={styles.contactType}>Main Emergency Number: </span>
								{data.main_emergency}
							</p>
							<p className={styles.contactRow}>
								<span className={styles.contactType}>Fire Emergency: </span>
								{data.fire_emergency}
							</p>
							<p className={styles.contactRow}>
								<span className={styles.contactType}>Medical Emergency: </span>
								{data.medical_emergency}
							</p>
						</div>

						<h4 className={styles.title}>Police and Law Enforcement</h4>
						{data.police_and_law_enforcement.map(renderContactInfo)}

						<h4 className={styles.title}>Other Important Numbers and Contact Information</h4>
						{data.other_important_numbers.map(renderContactInfo)}

						<h4 className={styles.title}>Embassies and Consulates</h4>
						{data.embassies_and_consulates.map(renderContactInfo)}
					</div>
				}
			/> */}

			<div className={styles.emergency}>
				<div className={styles.contactSection}>
					<p className={styles.contactRow}>
						<span className={styles.contactType}>Main Emergency Number: </span>
						{data.main_emergency}
					</p>
					<p className={styles.contactRow}>
						<span className={styles.contactType}>Fire Emergency: </span>
						{data.fire_emergency}
					</p>
					<p className={styles.contactRow}>
						<span className={styles.contactType}>Medical Emergency: </span>
						{data.medical_emergency}
					</p>
				</div>

				<h4 className={styles.title}>Police and Law Enforcement</h4>
				{data.police_and_law_enforcement.map(renderContactInfo)}

				<h4 className={styles.title}>Other Important Numbers and Contact Information</h4>
				{data.other_important_numbers.map(renderContactInfo)}

				<h4 className={styles.title}>Embassies and Consulates</h4>
				{data.embassies_and_consulates.map(renderContactInfo)}
			</div>
		</section>
	);
}
