import { useState } from 'react';

import { AiReportType, AreasItemType } from '../../../../api/types/apiGetReport';
import { Map } from '../../../../components';
import Config from '../../../../config';
import cn from '../../../../lib/classNames';
import { MAP_GROUP } from '../RiskBriefReport';
import TabButton from '../TabButton';

import styles from './LocationMap.module.scss';

type LocationMapType = {
	className?: string;
	data: AiReportType;
};

export default function LocationMap(props: LocationMapType) {
	const { className, data } = props;

	const [tab, setTab] = useState(MAP_GROUP[0]);

	const renderTabButton = MAP_GROUP.map((e) => {
		const list = e.getList(data as AiReportType);
		if (!list.length && e.field !== 'all') return null;

		return (
			<TabButton
				key={e.btnText}
				className={cn(styles['tab-button'], `btn-${e.field}`)}
				iconBefore={
					e.icon ? (
						<span className={`material-symbols-rounded btn-icon icon-${e.icon}`}>{e.icon}</span>
					) : undefined
				}
				onClick={() => setTab(e)}
				active={tab === e}
			>
				{e.btnText}
			</TabButton>
		);
	});

	const markersData = () => {
		if (tab.field === 'all') {
			const mergedArray: AreasItemType[] = [];

			MAP_GROUP.forEach((e) => {
				if (e.getList) {
					const screenArray = e.getList(data);
					if (screenArray) {
						mergedArray.push(...screenArray);
					}
				}
			});

			return mergedArray;
		}

		return tab.getList(data);
	};

	return (
		<section className={cn(styles['location-map'], className)}>
			<header className={styles.header}>
				<div className={styles['btn-wrap']}>{renderTabButton}</div>
			</header>
			<Map
				className={styles['map-container']}
				accessToken={Config.mapbox.accessTokenReport}
				mapStyle={Config.mapbox.styleReport}
				zoom={10}
				style={null}
				markers={markersData()}
			/>
		</section>
	);
}
