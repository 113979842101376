import { HeaderContentType, SummaryType } from '../../../../api/types/apiGetReport';
import Tabs from '../../components/ui/Tabs/Tabs';

import styles from './SummaryRiskBrief.module.scss';

type ParagraphListProps = {
	content: string | string[];
};

function ParagraphList({ content }: ParagraphListProps) {
	return (
		<div className={styles['paragraph-list']}>
			{Array.isArray(content) ? (
				content.map((paragraph) => (
					<p className={styles['text-indent']} key={paragraph}>
						{paragraph}
					</p>
				))
			) : (
				<p>{content}</p>
			)}
		</div>
	);
}

type ContentListType = {
	dataList: HeaderContentType[];
};

function ContentList({ dataList }: ContentListType) {
	return (
		<div className={styles['headered-list']}>
			{dataList.map((dataItem) => (
				<div key={dataItem.header} className={styles['list-item']}>
					<h4 className={styles['list-item-header']}>{dataItem.header}</h4>
					<p className={styles['list-item-content']}>{dataItem.content}</p>
				</div>
			))}
		</div>
	);
}

type OrderedListType = {
	dataList: HeaderContentType[];
};

function OrderedList({ dataList }: OrderedListType) {
	return (
		<ul className={styles['ordered-list']}>
			{dataList.map((dataItem) => (
				<li key={dataItem.header} className={styles['list-item']}>
					<span className={styles['list-item-header']}>{dataItem.header}</span>
					{dataItem.header && <>:&nbsp;</>}
					<span className={styles['list-item-content']}>{dataItem.content}</span>
				</li>
			))}
		</ul>
	);
}

type SummaryTabProps = {
	title: string;
	body: React.ReactNode;
};

function SummaryTab({ title, body }: SummaryTabProps) {
	return (
		<div className={styles['summary-tab']}>
			<h3 className={styles.header}>{title}</h3>
			<div className={styles['body-section']}>{body}</div>
		</div>
	);
}

type SummaryRiskBriefType = {
	summary: SummaryType;
};

export default function SummaryRiskBrief(props: SummaryRiskBriefType) {
	const { summary } = props;

	const {
		crime_and_security: crimeAndSecurity,
		health_and_safety: healthAndSafety,
		recommendations,
	} = summary.body;

	const tabData = [
		{
			label: 'BLUF summary',
			id: 'summary-bluf',
			content: (
				<SummaryTab
					title={summary.bluf.header}
					body={<ParagraphList content={summary.bluf.content} />}
				/>
			),
		},
		{
			label: 'Crime and Security',
			id: 'crime-and-security',
			content: (
				<SummaryTab title="Crime and Security" body={<ContentList dataList={crimeAndSecurity} />} />
			),
		},
		{
			label: 'Health and Safety',
			id: 'health-and-safety',
			content: (
				<SummaryTab title="Health and Safety" body={<ContentList dataList={healthAndSafety} />} />
			),
		},
		{
			label: 'Top Recommendations',
			id: 'top-recommendations',
			content: (
				<SummaryTab title="Top Recommendations" body={<OrderedList dataList={recommendations} />} />
			),
		},
	];

	return <Tabs tabs={tabData} initialTab="summary-bluf" />;
}
