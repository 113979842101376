import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AiReportType } from '../../../api/types/apiGetReport';
import Alert from '../../../components/Alert/Alert';
import useMediaQuery from '../../../hooks/MediaQuery';
import { DEVICES } from '../../../lib/breakpoints';
import cn from '../../../lib/classNames';
import Locations from '../RiskBriefReport/Locations/Locations';
import SectionTitle from '../RiskBriefReport/SectionTitle';

import map from './images/map.png';
import SummaryRiskBriefDemo from './SummaryRiskBriefDemo/SummaryRiskBriefDemo';
import InfoAction from './InfoAction';

import styles from './RiskBriefReportDemo.module.scss';

export type DataPlaceholderHospitalsType = {
	lat: string;
	long: string;
	name: string;
	notes: string;
	number: string;
	address: string;
	confidence: string;
};

export type DataPlaceholderSafestPlacesType = {
	lat: string;
	long: string;
	name: string;
	notes: string;
	confidence: string;
};

export type DataPlaceholderHighRiskType = {
	lat: string;
	long: string;
	name: string;
	notes: string;
	confidence: string;
};

export type DataPlaceholderType = {
	hospitals: DataPlaceholderHospitalsType[];
	safestPlaces: DataPlaceholderSafestPlacesType[];
	highRisk: DataPlaceholderHighRiskType[];
};

const dataPlaceholder: DataPlaceholderType = {
	hospitals: [
		{
			name: 'LOREM IPSUM DOLOR SIT.',
			notes:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis quas dolore saepe quis quibusdam tempore inventore earum! Labore, commodi provident.',
			number: '+|| |-||||-||||',
			address: 'Lorem ipsum dolor sit amet consectetur adipisicing.',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM IPSUM DOLOR SIT.',
			notes:
				'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis quas dolore saepe quis quibusdam tempore inventore earum! Labore, commodi provident.',
			number: '+|| |-||||-||||',
			address: 'Lorem ipsum dolor sit amet consectetur adipisicing.',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
	],
	safestPlaces: [
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, voluptas quibusdam?',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, voluptas quibusdam?',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, voluptas quibusdam?',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste, voluptas quibusdam?',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
	],
	highRisk: [
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque!',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque!',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque!',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
		{
			name: 'LOREM',
			notes: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Error, atque!',
			confidence: 'Lorem',
			lat: '',
			long: '',
		},
	],
};

export type RiskSafetySummaryDemoType = {
	buyReportLink?: string | null;
	dataDemo: AiReportType;
};

export default function RiskSafetySummaryDemo(props: RiskSafetySummaryDemoType) {
	const { buyReportLink, dataDemo } = props;

	const [active, setActive] = useState(false);

	const isMobile = useMediaQuery(DEVICES.isMobile);

	const showAction = () => {
		if (window.scrollY > 100) {
			setActive(true);
			document.removeEventListener('scroll', showAction);
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', showAction);

		return () => {
			document.removeEventListener('scroll', showAction);
		};
	}, []);

	// TODO: need to use dynamic data from API?
	const destination = 'Mexico City, Mexico';

	const unlockProText = 'Unlock the PRO report to receive more information';

	return (
		<main
			className={cn(styles['risk-safety-summary-demo-container'], 'risk-safety-summary-container')}
		>
			<div className={styles.content}>
				<Alert
					type="warning"
					icon="warning_amber"
					text="SAMPLE REPORT - Unlock the PRO report to receive an AI-powered Risk Intelligence Brief for your destination within minutes."
				/>
				<section className={cn(styles.section, styles['section-widget-wrap'])}>
					<h2 className="title">
						Risk Intelligence Brief for <strong>{destination}</strong>
					</h2>
				</section>

				<section className={cn(styles.section, styles['section-widget-wrap'])}>
					{dataDemo.summary.bluf.header && (
						<h3 className={styles.title}>{dataDemo.summary.bluf.header}</h3>
					)}
					<div className={cn(styles.description, styles.description)}>
						<p>{dataDemo.summary.bluf.content}</p>
					</div>
				</section>

				<SectionTitle
					title="Travel destination risk brief"
					subTitle="Ranking according to the Violent Crime Risk (ViCRI) Index for cities and countries"
				/>
				<SummaryRiskBriefDemo summary={dataDemo?.summary} />

				<SectionTitle
					title="Critical Numbers"
					subTitle="Including Local Emergency and Law Enforcement Information"
				/>

				<section className={cn(styles.section, styles['section-widget-wrap'])}>
					<div className={cn(styles.description, styles.description)}>
						<h4>Emergency Numbers in {destination}</h4>
						<ul>
							<li>
								<p className={styles['text-placeholder']}>
									<strong>|||</strong>: Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Similique nobis quaerat ipsam odio natus velit assumenda nostrum, officiis
									necessitatibus deleniti consequatur ea iusto illo nihil expedita, et commodi
									reiciendis numquam dignissimos qui. Officiis quia placeat omnis deserunt provident
									eius sequi.
								</p>
							</li>
						</ul>
						<h4>Law Enforcement in {destination}</h4>
						<ul>
							<li>
								<p className={styles['text-placeholder']}>
									<strong>Lorem ipsum dolor sit.</strong>: Lorem ipsum dolor sit amet consectetur,
									adipisicing elit. Ipsum quo distinctio totam aliquam, repudiandae dolor quam! A
									blanditiis dolores deleniti.{' '}
								</p>
							</li>
						</ul>
						<h4>Other Important Numbers and Contact Information in {destination}</h4>
						<ul>
							<li>
								<p className={styles['text-placeholder']}>
									<strong>Lorem ipsum dolor sit amet.</strong>: Lorem ipsum dolor sit amet
									consectetur adipisicing elit. Rerum quidem, debitis odio perferendis dolorem
									soluta.
								</p>
							</li>
						</ul>
						<p className={styles['text-placeholder']}>
							Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium temporibus
							fugiat rem eveniet libero perspiciatis, dignissimos vel molestiae culpa voluptates
							asperiores aspernatur eligendi repudiandae doloribus. Aliquam nesciunt vel adipisci
							modi architecto iure nulla debitis neque nobis. Atque iste veniam reiciendis?
						</p>
					</div>
				</section>

				<SectionTitle
					title="Significant Locations"
					subTitle="Including Selected Hospitals, Safe Areas, High-Risk Neighborhoods, etc."
				/>

				<Locations dataDemo={dataDemo} dataPlaceholder={dataPlaceholder} variant="demo" />

				{isMobile && (
					<InfoAction
						className={cn(styles.section, styles['section-widget-wrap'])}
						buyReportLink={buyReportLink}
					/>
				)}

				<section
					className={cn(styles.section, styles['section-widget-wrap'], styles['section-map'])}
				>
					<header>
						<div className={styles['btn-wrap']}>
							<button
								className={cn(styles.button, styles.active)}
								title={unlockProText}
								disabled
								type="button"
							>
								<span className={styles['btn-text']}>All</span>
							</button>
							<button
								className={cn(styles.button, styles.disabled)}
								title={unlockProText}
								disabled
								type="button"
							>
								<span className="material-symbols-rounded btn-icon icon-dangerous">dangerous</span>
								<span className={styles['btn-text']}>High Risk areas</span>
							</button>
							<button
								className={cn(styles.button, styles.disabled)}
								title={unlockProText}
								disabled
								type="button"
							>
								<span className="material-symbols-rounded btn-icon icon-encrypted">encrypted</span>
								<span className={styles['btn-text']}>Safest places</span>
							</button>
							<button
								className={cn(styles.button, styles.disabled)}
								title={unlockProText}
								disabled
								type="button"
							>
								<span className="material-symbols-rounded btn-icon icon-emergency">emergency</span>
								<span className={styles['btn-text']}>Hospitals</span>
							</button>
						</div>
					</header>
					<div className={styles['content-wrap']}>
						<img className={styles['map-placeholder']} src={map} alt="" />
					</div>
				</section>
				<CSSTransition
					in={!isMobile && active}
					timeout={100}
					classNames={{
						enterDone: styles['info-action-fixed-done-enter'],
					}}
					unmountOnExit
				>
					<InfoAction className={styles['info-action-fixed']} buyReportLink={buyReportLink} />
				</CSSTransition>
			</div>
		</main>
	);
}
